import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HeadProvider } from 'react-head';
import { SWRConfig } from 'swr';
import safeStringify from 'fast-safe-stringify';
import 'chart.js/auto';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

import Home from 'pages/Home';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';
import SmartBid from 'pages/SmartBid';
import OptiRates from 'pages/OptiRates';
import Revenue360 from 'pages/Revenue360';
import Settings from 'pages/Settings';
import Allowlist from 'pages/Allowlist';
import Admin from 'pages/Admin';
import Error404 from 'pages/404';
import LISAAnalytics from 'pages/LISAAnalytics';
import MarketMap from 'pages/MarketMap';
import DriverManagerPage from 'pages/DriverManager';
import VehicleManagerPage from 'pages/VehicleManager';
import { UserProvider, PreviewProvider } from 'components/Accounts';
import { CompaniesProvider } from 'providers/Companies';
import { VehiclesProvider } from 'providers/Vehicles';
// import { SocketProvider } from 'components/SocketProvider';

Chart.register(annotationPlugin);

function localStorageProvider() {
    let map = new Map();
    if (typeof window !== 'undefined') {
        // When initializing, we restore the data from `localStorage` into a map.
        map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'));

        // Before unloading the app, we write back all the data into `localStorage`.
        window.addEventListener('beforeunload', () => {
            const appCache = safeStringify(Array.from(map.entries()));
            localStorage.setItem('app-cache', appCache);
        });
    }
    // We still use the map for write & read for performance.
    return map;
}

const App = () => {
    return (
        <Router>
            <HeadProvider>
                <SWRConfig value={{ provider: localStorageProvider }}>
                    {/* <SocketProvider> */}
                    <PreviewProvider>
                        <UserProvider>
                            <CompaniesProvider>
                                <VehiclesProvider>
                                    <Toaster
                                        position='bottom-right'
                                        toastOptions={{
                                            style: {
                                                fontSize: '0.875rem',
                                                background:
                                                    'var(--everest-black)',
                                                color: 'var(--everest-white)',
                                            },
                                        }}
                                    >
                                        {(t) => (
                                            <ToastBar toast={t}>
                                                {({ icon, message }) => (
                                                    <>
                                                        {icon}
                                                        <span
                                                            onClick={() =>
                                                                toast.dismiss(
                                                                    t.id
                                                                )
                                                            }
                                                        >
                                                            {message}
                                                        </span>
                                                    </>
                                                )}
                                            </ToastBar>
                                        )}
                                    </Toaster>
                                    <Routes>
                                        <Route
                                            exact
                                            path='/'
                                            element={<Home />}
                                        />
                                        <Route
                                            path='/login'
                                            element={<Login />}
                                        />
                                        <Route
                                            path='/reset-password'
                                            element={<ResetPassword />}
                                        />
                                        <Route
                                            path='/smartbid'
                                            element={<SmartBid />}
                                        />
                                        {/* 
                                        // Removed due to the absence of current users
                                        <Route
                                            path='/optirates'
                                            element={<OptiRates />}
                                        />
                                        <Route
                                            path='/revenue360'
                                            element={<Revenue360 />}
                                        />
                                        */}
                                        <Route
                                            path='/lisa-analytics'
                                            element={<LISAAnalytics />}
                                        />
                                        <Route
                                            path='/market-map'
                                            element={<MarketMap />}
                                        />
                                        <Route
                                            path='/settings'
                                            element={<Settings />}
                                        />
                                        <Route
                                            path='/allowlist'
                                            element={<Allowlist />}
                                        />
                                        <Route
                                            path='/admin'
                                            element={<Admin />}
                                        />
                                        <Route
                                            path='/driver-manager'
                                            element={<DriverManagerPage />}
                                        />
                                        <Route
                                            path='/vehicle-manager'
                                            element={<VehicleManagerPage />}
                                        />
                                        <Route
                                            path='*'
                                            element={<Error404 />}
                                        />
                                    </Routes>
                                </VehiclesProvider>
                            </CompaniesProvider>
                        </UserProvider>
                    </PreviewProvider>
                    {/* </SocketProvider> */}
                </SWRConfig>
            </HeadProvider>
        </Router>
    );
};

export default App;
